var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.tracks.length == 0
        ? _c("div", [_vm._v(_vm._s(_vm.$t("common.nothing found")))])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-form-group",
        [
          _c("b-form-radio-group", {
            attrs: { options: _vm.options },
            on: { change: _vm.onChange },
            model: {
              value: _vm.selected,
              callback: function($$v) {
                _vm.selected = $$v
              },
              expression: "selected"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-button",
        {
          staticClass: "add-button",
          attrs: { variant: "outline-light", size: "sm" },
          on: { click: _vm.onAdd }
        },
        [_vm._v(_vm._s(_vm.$t("form.common.button.add")))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }