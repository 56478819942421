var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "buttons" },
    [
      _vm.hasPrev
        ? _c(
            "b-button",
            {
              staticClass: "play-prev",
              attrs: { variant: "outline-light" },
              on: { click: _vm.onPrev }
            },
            [_vm._v("← " + _vm._s(_vm.$t("common.previous")))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.pageItems, function(item, index) {
        return _c("item", {
          key: item.name,
          attrs: { item: item, index: index }
        })
      }),
      _vm._v(" "),
      _vm.hasNext
        ? _c(
            "b-button",
            {
              staticClass: "play-next",
              attrs: { variant: "outline-light" },
              on: { click: _vm.onNext }
            },
            [_vm._v(_vm._s(_vm.$t("common.next")) + " →")]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }