import { render, staticRenderFns } from "./MagnetURI.vue?vue&type=template&id=65e74adf&scoped=true&"
import script from "./MagnetURI.vue?vue&type=script&lang=js&"
export * from "./MagnetURI.vue?vue&type=script&lang=js&"
import style0 from "./MagnetURI.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./MagnetURI.vue?vue&type=style&index=1&id=65e74adf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65e74adf",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Volumes/Home/vintikzzzz/Projects/webtor/webtor-web-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('65e74adf')) {
      api.createRecord('65e74adf', component.options)
    } else {
      api.reload('65e74adf', component.options)
    }
    module.hot.accept("./MagnetURI.vue?vue&type=template&id=65e74adf&scoped=true&", function () {
      api.rerender('65e74adf', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "ui/src/components/App/Show/MagnetURI.vue"
export default component.exports