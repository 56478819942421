var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      directives: [
        {
          name: "b-modal",
          rawName: "v-b-modal.share-link-modal",
          modifiers: { "share-link-modal": true }
        }
      ],
      staticClass: "btn share-link my-btn-link",
      attrs: { href: _vm.shareLink },
      on: {
        click: function($event) {
          $event.preventDefault()
          $event.stopPropagation()
        }
      }
    },
    [
      _c(
        "span",
        { staticClass: "icon-wrapper" },
        [_c("icon", { attrs: { name: "share" } })],
        1
      ),
      _vm._v(_vm._s(_vm.$tm("common.share link")) + "\n    "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "share-link-modal",
            centered: "",
            "no-fade": "",
            title: _vm.$tm("common.share link")
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function(ref) {
                var ok = ref.ok
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "primary" },
                      on: {
                        click: function($event) {
                          _vm.copyUrl()
                          ok()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$tm("common.copy link")))]
                  )
                ]
              }
            }
          ])
        },
        [
          _c("b-input", {
            ref: "input",
            attrs: { readonly: "", value: _vm.shareLink }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }