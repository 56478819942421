var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c("div", [_vm._v(_vm._s(_vm.$t("common.loading")))])
        : _vm._e(),
      _vm._v(" "),
      _vm.empty
        ? _c("div", [_vm._v(_vm._s(_vm.$t("common.nothing found")))])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-form-group",
        [
          _c("b-form-radio-group", {
            attrs: { options: _vm.options },
            on: { change: _vm.onChange },
            model: {
              value: _vm.selected,
              callback: function($$v) {
                _vm.selected = $$v
              },
              expression: "selected"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasTracks
        ? _c("div", {
            staticClass: "osdb",
            domProps: { innerHTML: _vm._s(_vm.$tm("osdb.reference")) }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }