var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "b-button",
        {
          staticClass: "continue",
          attrs: { variant: "outline-light" },
          on: { click: _vm.onContinue }
        },
        [
          _vm._v(
            _vm._s(_vm.$t("common.continueFrom")) +
              " " +
              _vm._s(_vm.time.toString().toHHMMSS())
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "b-button",
        {
          staticClass: "restart",
          attrs: { variant: "outline-light" },
          on: { click: _vm.onRestart }
        },
        [_vm._v(_vm._s(_vm.$t("common.restart")))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }