var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "b-button-group",
        _vm._l(_vm.buttons, function(btn, name) {
          return _c(
            "b-button",
            {
              key: name,
              attrs: {
                pressed: name == _vm.selected,
                variant: "outline-light"
              },
              on: {
                click: function($event) {
                  return _vm.select(name)
                }
              }
            },
            [_vm._v(_vm._s(btn.caption))]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "overflow-auto" },
        [
          _c(
            "keep-alive",
            [_c(_vm.buttons[_vm.selected].component, { tag: "component" })],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-button",
        {
          staticClass: "track-close",
          attrs: { variant: "outline-light" },
          on: { click: _vm.onClose }
        },
        [_vm._v(_vm._s(_vm.$t("form.common.button.close")))]
      ),
      _vm._v(" "),
      _c("size", { staticClass: "track-size" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }