var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [_vm._v(_vm._s(_vm.$t("nav.torrent to ddl")))]
          },
          proxy: true
        },
        {
          key: "lead",
          fn: function() {
            return [_vm._v(_vm._s(_vm.$t("torrent to ddl.lead")))]
          },
          proxy: true
        },
        {
          key: "features",
          fn: function() {
            return [
              _c("ul", [
                _c("li", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "torrent to ddl.features.no file size restrictions"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("torrent to ddl.features.no registration required")
                    )
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("torrent to ddl.features.no software required")
                    )
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    _vm._s(_vm.$t("torrent to ddl.features.absolutely free"))
                  )
                ])
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [_vm._v(" "), _vm._v(" "), _vm._v(" "), [_c("send")]],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }