var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      directives: [
        {
          name: "b-modal",
          rawName: "v-b-modal.magnet-uri-modal",
          modifiers: { "magnet-uri-modal": true }
        }
      ],
      staticClass: "btn magnet-uri my-btn-link",
      attrs: { href: _vm.magnetURI },
      on: {
        click: function($event) {
          $event.preventDefault()
          $event.stopPropagation()
        }
      }
    },
    [
      _c(
        "span",
        { staticClass: "icon-wrapper" },
        [_c("icon", { attrs: { name: "magnet" } })],
        1
      ),
      _vm._v(_vm._s(_vm.$tm("common.magnet uri")) + "\n    "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "magnet-uri-modal",
            centered: "",
            "no-fade": "",
            title: _vm.$tm("common.magnet uri")
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function(ref) {
                var ok = ref.ok
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "primary" },
                      on: {
                        click: function($event) {
                          _vm.copyUrl()
                          ok()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$tm("common.copy link")))]
                  )
                ]
              }
            }
          ])
        },
        [
          _c("b-form-textarea", {
            ref: "input",
            attrs: {
              rows: "3",
              "max-rows": "15",
              readonly: "",
              value: _vm.magnetURI
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }