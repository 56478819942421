var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    [
      _c(
        "b-alert",
        { attrs: { show: this.status == "success", variant: "success" } },
        [_vm._v(_vm._s(_vm.$tm("form.common.success")))]
      ),
      _vm._v(" "),
      _c(
        "b-alert",
        { attrs: { show: this.status == "error", variant: "danger" } },
        [_vm._v(_vm._s(_vm.$tm("form.common.error")))]
      ),
      _vm._v(" "),
      _c(
        "b-alert",
        { attrs: { show: this.status == "exists", variant: "warning" } },
        [_vm._v(_vm._s(_vm.$tm("form.support.error.exists")))]
      ),
      _vm._v(" "),
      _c(
        "b-alert",
        { attrs: { show: this.status == "submitting", variant: "info" } },
        [_vm._v(_vm._s(_vm.$tm("form.common.submitting")))]
      ),
      _vm._v(" "),
      _vm.show
        ? _c(
            "b-form",
            {
              on: {
                submit: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.onSubmit($event)
                },
                reset: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.onReset($event)
                }
              }
            },
            [
              _c("h1", [_vm._v(_vm._s(_vm.$tm("form.support.header")))]),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "cause",
                    label: _vm.$tm("form.support.field.cause.label"),
                    "label-for": "cause"
                  }
                },
                [
                  _c("b-form-select", {
                    attrs: { id: "cause", options: _vm.cause, required: "" },
                    model: {
                      value: _vm.form.cause,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "cause", $$v)
                      },
                      expression: "form.cause"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "subject-group",
                    label: _vm.$tm("form.support.field.subject.label"),
                    "label-for": "subject"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "subject",
                      required: "",
                      placeholder: _vm.$tm(
                        "form.support.field.subject.placeholder"
                      )
                    },
                    model: {
                      value: _vm.form.subject,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "subject", $$v)
                      },
                      expression: "form.subject"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "description-group",
                    label: _vm.$tm("form.support.field.description.label"),
                    "label-for": "description"
                  }
                },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      id: "description",
                      placeholder: _vm.$tm(
                        "form.support.field.description.placeholder"
                      ),
                      rows: "3",
                      "max-rows": "6",
                      required: ""
                    },
                    model: {
                      value: _vm.form.description,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "description", $$v)
                      },
                      expression: "form.description"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              !_vm.isQuestion && _vm.hasCause
                ? _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "infohash-group",
                        label: _vm.$tm("form.support.field.infohash.label"),
                        "label-for": "infohash"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "infohash",
                          required: "",
                          state: this.infohashState,
                          placeholder: _vm.$tm(
                            "form.support.field.infohash.placeholder"
                          ),
                          formatter: _vm.infohashFormatter
                        },
                        model: {
                          value: _vm.form.infohash,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "infohash", $$v)
                          },
                          expression: "form.infohash"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "b-form-invalid-feedback",
                        { attrs: { id: "infohash-feedback" } },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$tm("form.support.field.infohash.error")
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isQuestion && _vm.hasCause
                ? _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "filename-group",
                        label: _vm.$tm("form.support.field.filename.label"),
                        "label-for": "filename"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "filename",
                          placeholder: _vm.$tm(
                            "form.support.field.filename.placeholder"
                          )
                        },
                        model: {
                          value: _vm.form.filename,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "filename", $$v)
                          },
                          expression: "form.filename"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "email-group",
                    label: _vm.$tm("form.support.field.email.label"),
                    "label-for": "email"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "email",
                      type: "email",
                      required: "",
                      placeholder: _vm.$tm(
                        "form.support.field.email.placeholder"
                      )
                    },
                    model: {
                      value: _vm.form.email,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "email", $$v)
                      },
                      expression: "form.email"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.isIllegal
                ? _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "work-group",
                        label: _vm.$tm("form.support.field.work.label"),
                        "label-for": "work"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "work",
                          required: "",
                          placeholder: _vm.$tm(
                            "form.support.field.work.placeholder"
                          )
                        },
                        model: {
                          value: _vm.form.work,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "work", $$v)
                          },
                          expression: "form.work"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "buttons" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        type: "submit",
                        disabled: _vm.isSubmitting,
                        variant: "primary"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$tm("form.common.button.submit")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    { attrs: { type: "reset", variant: "danger" } },
                    [_vm._v(_vm._s(_vm.$tm("form.common.button.reset")))]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }