var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [_vm._v(_vm._s(_vm.$t("nav.magnet to torrent")))]
          },
          proxy: true
        },
        {
          key: "lead",
          fn: function() {
            return [_vm._v(_vm._s(_vm.$t("magnet to torrent.lead")))]
          },
          proxy: true
        }
      ])
    },
    [_vm._v(" "), _vm._v(" "), [_c("magnet", { attrs: { size: "lg" } })]],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }