var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { ref: "container", class: _vm.className }),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide-fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showAdWithDelay && !_vm.adClosed,
                expression: "showAdWithDelay && !adClosed"
              }
            ],
            staticClass: "ad"
          },
          [
            _c("span", {
              staticClass: "close",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.closeAd($event)
                }
              }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "ad-label" }, [_vm._v("[ad]")]),
            _vm._v(" "),
            _c("adsense-ad", {
              ref: "adsense",
              attrs: {
                "ad-name": "player",
                "ad-style": "display:inline-block;width:300px;height:250px"
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }