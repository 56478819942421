var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { class: _vm.className }, [
    _c("span", { staticClass: "container" }, [
      _c("span", { staticClass: "resizer", style: _vm.style }, [
        _c("span", { domProps: { innerHTML: _vm._s(_vm.caption) } })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }