import { render, staticRenderFns } from "./Captions.vue?vue&type=template&id=30324068&"
import script from "./Captions.vue?vue&type=script&lang=js&"
export * from "./Captions.vue?vue&type=script&lang=js&"
import style0 from "./Captions.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Volumes/Home/vintikzzzz/Projects/webtor/webtor-web-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('30324068')) {
      api.createRecord('30324068', component.options)
    } else {
      api.reload('30324068', component.options)
    }
    module.hot.accept("./Captions.vue?vue&type=template&id=30324068&", function () {
      api.rerender('30324068', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "ui/src/components/App/Show/Player/MediaElement/Tracks/Captions.vue"
export default component.exports