var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form",
    { attrs: { inline: "" } },
    [
      _c("label", { staticClass: "mr-sm-2" }, [
        _vm._v(_vm._s(_vm.$t("common.size")) + ":")
      ]),
      _vm._v(" "),
      _c("b-input", {
        staticClass: "mb-2 mr-sm-2 mb-sm-0 caption-size",
        attrs: { readonly: "", disabled: "" },
        model: {
          value: _vm.size,
          callback: function($$v) {
            _vm.size = $$v
          },
          expression: "size"
        }
      }),
      _vm._v(" "),
      _c(
        "b-button-group",
        [
          _c(
            "b-button",
            { attrs: { variant: "outline-light" }, on: { click: _vm.smaller } },
            [_vm._v("–")]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            { attrs: { variant: "outline-light" }, on: { click: _vm.bigger } },
            [_vm._v("+")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }