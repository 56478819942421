var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.className }, [
    _c("div", { staticClass: "pieces-container" }, [
      _c(
        "svg",
        {
          staticClass: "pieces",
          attrs: {
            viewBox: "0 0 " + this.progressLength + " 100",
            xmlns: "http://www.w3.org/2000/svg"
          }
        },
        [
          _c("rect", {
            attrs: {
              x: "0",
              y: "0",
              width: this.progressLength,
              height: "100",
              opacity: "0.3"
            }
          }),
          _vm._v(" "),
          _vm._l(this.highPrioritySegments, function(s) {
            return _c("rect", {
              attrs: {
                x: s.start,
                y: "0",
                width: s.end - s.start,
                height: "100",
                opacity: "0.3"
              }
            })
          }),
          _vm._v(" "),
          _vm._l(this.completedSegments, function(s) {
            return _c("rect", {
              attrs: {
                x: s.start,
                y: "0",
                width: s.end - s.start,
                height: "100"
              }
            })
          })
        ],
        2
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "web-seeder" }, [
      _vm._v("\n      " + _vm._s(_vm.webSeederMessage) + "\n    ")
    ]),
    _vm._v(" "),
    _vm.showTranscoderMessage && !_vm.download
      ? _c("div", { staticClass: "transcoder" }, [
          _vm._v("\n      " + _vm._s(_vm.transcoderMessage) + "\n    ")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }