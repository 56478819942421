var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-button",
    {
      attrs: { disabled: _vm.disabled, variant: "outline-light" },
      on: { click: _vm.onClick }
    },
    [_vm._v(_vm._s(_vm.friendlyNameCutted))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }