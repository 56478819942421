var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      staticClass: "btn btn-primary btn-lg",
      attrs: { role: "button" },
      on: { click: _vm.onClick }
    },
    [
      _c("span", { staticClass: "heart" }, [_vm._v("❤️")]),
      _c("span", { staticClass: "text" }, [
        _vm._v(_vm._s(_vm.$tm("common.donate")))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }