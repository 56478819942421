<template>
    <layout>
        <h1>Torrent to Magnet</h1>
    </layout>
</template>

<script>
import Layout from '../Layouts/Default.vue';
export default {
    components: {Layout},
}
</script>

<style>

</style>