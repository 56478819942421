var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "overflow-auto" }, [
        _c(
          "div",
          { staticClass: "text-center" },
          [
            _c(
              "b-button",
              {
                staticClass: "copy",
                attrs: { variant: "outline-light" },
                on: { click: _vm.onCopy }
              },
              [_vm._v(_vm._s(_vm.$t("common.copy hls")))]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "b-button",
        {
          staticClass: "back",
          attrs: { variant: "outline-light" },
          on: { click: _vm.onBack }
        },
        [_vm._v(_vm._s(_vm.$t("common.back")))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }